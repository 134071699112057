import axios from 'axios';
import { useEffect, useState } from 'react';

export default function useFetch(url, params = {}) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetch, setFetch] = useState(0);

  useEffect(() => {
    setLoading(true);
    axios
      .get(url, {
        params: params,
      })
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, fetch]);
  return { data, error, loading, setFetch };
}
